import { truncateAddress } from "../../utils";

import { useState, useEffect, useRef, useContext } from "react"

import starImg from './../../assets/img/star.webp'
import tradeUpImg from './../../assets/img/trade-up.webp'
import walletImg from './../../assets/img/wallet.webp'
import listImg from './../../assets/img/list.svg'
import punishmentsImg from './../../assets/img/punishments.webp'

import config from "../../config"
import { Link, Navigate } from "react-router-dom";
import { PageContext } from "../../context";
import buyNft from "../../contracts/BuyNFT";

import styles from './Rewards.module.sass'

export default function Rewards ({ totalInvitation, isReferralList}) {

  document.title = 'Raiders • Rewards'
  const [copyTitle, setCopyTitle] = useState('Copy invitation')

  const service = useContext(PageContext)

  const [raider, setRaider] = useState({
    level: 0,
    bonus: 0,
    withdrawal: 0,
    income: 0,
    totalRef: 0,
    totalRefActive: 0
  })

  console.log(isReferralList)

  async function updateInfo() {
    const raider = await buyNft.getRaider()
    
    setRaider({
      level: raider.level,
      bonus: raider.levelReward,
      totalRef: raider.referrals,
      totalRefActive: raider.levelVolume - raider.volume,
      withdrawal: raider.reward,
      income: raider.income
    })
  }

  const submitWithdraw = async () => {
    await buyNft.claimReward(service.currentAccount)
    updateInfo()
  }

  const copyBtn = () => {
    const textArea = document.createElement('textarea');
    textArea.style.height = '0px';
    textArea.value = `${config.ref}/dao/referrals?ref=${service.currentAccount}`;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
    setCopyTitle('Copied !')
    setTimeout(() => {
      setCopyTitle('Invite new team member')
    }, 1500);
  }

  useEffect(() => {
    updateInfo()
  }, [])
  
  // if (service.role <= -1) return <Navigate to='/dao/voting' replace/>
  return (
      <div className={styles.rewards}>
          <div className={`${styles.rewards__container} container`}>
            <div className={styles.rewards__title}>
              <Link to="/dao/voting" className={styles.rewards__back}>
                <span className={styles.rewards__back_arrow}/>
                <p className={styles.rewards__back_descr}>
                  Referral &amp;&nbsp;Rewards
                </p>
              </Link>
            </div>
            <div className={styles.rewards__info}>
              <div className={styles.rewards__player}>
                <p className={styles.rewards__name}>
                  {service.name}
                </p>
                <div className={styles.rewards__avatar} style={{
                  backgroundImage: `${service.yourAvatar}`
                }}/>
                <p className={styles.rewards__address}>
                  {truncateAddress(service.currentAccount)}
                </p>
                <div className={styles.rewards__name_address}>
                  <p className={styles.rewards__name}>
                    {service.name}
                  </p>
                  <p className={styles.rewards__address}>
                    {truncateAddress(service.currentAccount)}
                  </p>
                  <p className={styles.rewards__rank} style={{
                    color: service.displayRole === 'Superadmin' ? '#ffff00' : (service.displayRole === 'Duke' ? '#f40101' : (service.displayRole === 'Earl' ? '#00ffff' : (service.displayRole === 'Baron' ? '#00ff00' : '#efefef')))
                  }}>
                    {service.displayRole}
                  </p>
                </div>
              </div>
              <p className={styles.rewards__rank} style={{
                color: service.displayRole === 'Superadmin' ? '#ffff00' : (service.displayRole === 'Duke' ? '#f40101' : (service.displayRole === 'Earl' ? '#00ffff' : (service.displayRole === 'Baron' ? '#00ff00' : '#efefef')))
              }}>
                {service.displayRole}
              </p>
              <div className={styles.rewards__level}>
                <img className={styles.rewards__icon} src={starImg} alt=''/>
                <p className={styles.rewards__descr}>
                  Level:
                </p>
                <p className={styles.rewards__number}>{raider.level > 0 ? raider.level : 'NA'}</p>
                {/* <p className={styles.rewards__number}>{service.lvl != null ? service.lvl : 'NA'}</p>  можно еще вот так и так правильней */} 
              </div>
              <div className={styles.rewards__bonus}>
                <img className={styles.rewards__icon} src={tradeUpImg} alt=''/>
                <p className={styles.rewards__descr}>
                  Rewards bonus:
                </p>
                <p className={styles.rewards__number}>{raider.bonus}%</p>
                {/* <p className={styles.rewards__number}>{service.bonus != null ? service.bonus : 0}%</p> */}
                <span className={`${styles.rewards__tooltip} ${styles.rewards__tooltip1}`}>
                  ?
                  <p className={styles.rewards__tooltip_some}>
                    Increase your reward % by inviting new members. See the Constitution for details.
                  </p>
                </span>
              </div>
            </div>
            <div className={styles.rewards__withdraw}>
              <div className={styles.rewards__total}>
                <img className={styles.rewards__icon} src={walletImg} alt=''/>
                <p className={styles.rewards__descr}>
                  Total income:
                </p>
                <p className={styles.rewards__number}>{raider.income} USDC</p>
                {/* <p className={styles.rewards__number}>{service.income != null ? service.income : 0} USDC</p> */}
              </div>
              <div className={styles.rewards__amount}>
                <img className={styles.rewards__icon} src={walletImg} alt=''/>
                <p className={styles.rewards__descr}>
                  Withdrawal amount:
                </p>
                <p className={styles.rewards__number}>{raider.withdrawal} USDC</p>
                {/* <p className={styles.rewards__number}>{service.withdrawal != null ? service.withdrawal : 0} USDC</p> */}
              </div>
              <button className={styles.rewards__btn} onClick={submitWithdraw} disabled={raider.withdrawal <= 0} style={{
                filter: raider.withdrawal <= 0 ? 'brightness(0.75)' : ''
              }} >
                Withdraw the funds
              </button>
            </div>
            <div className={styles.rewards__team}>
              <div className={styles.rewards__place}>
                <img className={styles.rewards__icon} src={listImg} alt=''/>
                <p className={styles.rewards__descr}>
                  Your team:
                </p>
                <p className={styles.rewards__number}>
                  {/* вот так было, эти данные исходя из общего запроса на профиль, поэтому не знаю как лучше, мб отдельный фетч создать */}
                  {/* {isReferralList != null ? isReferralList.length : '0'}  */}
                  {raider.totalRef}
                </p>
              </div>
              <div className={styles.rewards__invite}>
                <img className={styles.rewards__icon} src={punishmentsImg} alt=''/>
                <p className={styles.rewards__descr}>
                  Invitations available:
                </p>
                <p className={styles.rewards__number}>
                  {/* {totalInvitation} */}
                  {raider.totalRefActive}
                </p>
                <span className={`${styles.rewards__tooltip} ${styles.rewards__tooltip2}`}>
                  ?
                  <p className={styles.rewards__tooltip_some}>
                    Use your all invitations per month to increase your amount of invitations
                  </p>
                </span>
              </div>
              <button className={styles.rewards__btn} onClick={copyBtn} disabled={raider.level <= 0} style={{
                filter: raider.level <= 0 ? 'brightness(0.75)' : ''
              }} >
                {copyTitle}
              </button>
            </div>
            <div className={styles.rewards__adaptive}>
                <button className={styles.rewards__btn} onClick={submitWithdraw} disabled={raider.withdrawal <= 0} style={{
                  filter: raider.withdrawal <= 0 ? 'brightness(0.75)' : ''
                }} >
                  Withdraw the funds
              </button>
              <button className={styles.rewards__btn} onClick={copyBtn} disabled={raider.level <= 0} style={{
                filter: raider.level <= 0 ? 'brightness(0.75)' : ''
              }} >
                {copyTitle}
              </button>
            </div>
            <div className={styles.rewards__players}>
              <ul className={styles.rewards__list}>
                {(isReferralList ? isReferralList : []).map((x, i) => {
                  return (
                    <li key={i} className={styles.rewards__item}>
                      <div className={styles.rewards__left}>
                        <p className={styles.rewards__index}>
                          #{i+1}
                        </p>
                        <div className={styles.rewards__photo} style={{
                          backgroundImage: x.avatar
                        }}/>
                        <p className={styles.rewards__name}>
                          {x.name == null ? 'No name' : x.name}
                        </p>
                        <p className={styles.rewards__wallet}>
                          {x.address === 'Loading...' ? x.address : truncateAddress(x.address)}
                        </p>
                        <div className={styles.rewards__name_wallet}>
                          <p className={styles.rewards__name}>
                            {x.name == null ? 'No name' : x.name}
                          </p>
                          <p className={styles.rewards__wallet}>
                            {x.address === 'Loading...' ? x.address : truncateAddress(x.address)}
                          </p>
                        </div>
                      </div>
                      <div className={styles.rewards__right}>
                        <p className={styles.rewards__members}>
                          Members invited: <span>{x.invited}</span>
                        </p>
                        <p className={styles.rewards__income}>
                          Total income: <span>{x.income} USDC</span>
                        </p>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
  )
}